import { SEO } from "components/common/SEO";
import { graphql } from "gatsby";
import React, { PropsWithChildren } from "react";

import Articles from "../components/blog/Articles/Articles";
import Sidebar from "../components/blog/Sidebar/Sidebar";
import Layout from "../components/common/layout/layout";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";
import Pagination from "../components/pagination/Pagination";

const BlogCategoryPage = (props: PropsWithChildren<any>) => {
  const articles = props.data.articles.nodes;
  const categories = props.data.categories.nodes;
  return (
    <Layout>
      <div className={"o-hero o-hero--blog"}>
        <div className="container p-vertical-xl">
          <h1 className={"a-title a-title--hero"}>
            <strong>{props.pageContext.category.name}</strong> <br />
            {props.pageContext.category.description || `Articulos de C&R Seguridad`}
          </h1>
        </div>
      </div>
      <div className="container grid-70-30 grid-70-30--blog p-vertical-l">
        <main>
          <Articles articles={articles} />
          <Pagination pageContext={props.pageContext} />
        </main>
        <aside>
          <Sidebar categories={categories} />
        </aside>
      </div>
    </Layout>
  );
};

export const Head = ({ pageContext }) => (
  <SEO
    title={pageContext.category.seo_title}
    description={pageContext.category.seo_description || "---"}
    robots={pageContext.category.seo_robots}
    canonical={`blog/${pageContext.categorySlug}/`}
  />
);

export const query = graphql`
  query ArticlesByCategory($skip: Int!, $limit: Int!, $categorySlug: String!) {
    articles: allSanityArticle(
      sort: { order: DESC, fields: date }
      skip: $skip
      limit: $limit
      filter: { category: { slug: { current: { eq: $categorySlug } } } }
    ) {
      nodes {
        category {
          name
          slug {
            current
          }
        }
        id
        title
        slug {
          current
        }
        thumbnailText
        thumbnail {
          asset {
            url
            gatsbyImageData
            id
            _id
          }
        }
      }
    }
    categories: allSanityCategory(
      sort: { fields: name, order: ASC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        name
        slug {
          current
        }
        id
      }
    }
  }
`;

export default BlogCategoryPage;
